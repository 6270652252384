import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "course-image-2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Code of practise" />
      <BackgroundImage
        Tag="div"
        className="bodhi__section-image-header"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <h1>Code of practise</h1>
      </BackgroundImage>
      <div
        id="service_2"
        className="course__services bodhi__bg-pattern margin-header"
      >
        <div className="container">
          <div className="magin-bottom-40">
            <div class="row">
              <div className="col-sm-12">
                <h3>
                  Bodhi follows the Education (Pastoral Care of Tertiary and
                  International Learners) Code of Practice 2021 (the Code)
                </h3>
                <p>
                  The Code guides and monitors Bodhi's role and responsibilities
                  to support the wellbeing and safety of all students.
                </p>

                <a
                  href="https://www2.nzqa.govt.nz/assets/Tertiary/The-Code/pastoral-care-code-of-practice-2021-english.pdf"
                  target="blank"
                >
                  <p>
                    The Education (Pastoral Care of Tertiary and International
                    Learners) Code of Practice 2021
                  </p>
                </a>

                <a
                  href="https://www2.nzqa.govt.nz/assets/Tertiary/The-Code/pastoral-care-code-of-practice-2021-maori.pdf"
                  target="blank"
                >
                  <p>
                    Ngā Tikanga Mahi Mātauranga (Te Manaaki i Ngā Ākonga
                    Mātauranga Matua me Ērā o Tāwāhi) 2021
                  </p>
                </a>

                <p>
                  Annually Bodhi conducts a self review of its policies and
                  practices against the code, to assess and monitor its
                  implementation.
                  <a
                    href="/pdf/Online-Code-of-Practice-Review-2023-Bodhi.pdf"
                    target="blank"
                  >
                    Click here for the current review.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Testimonials
